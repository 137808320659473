<template>
	<div class="header">
    <div class="header_bg">
      <div class="header_box">
        <div class="header_top">
          <div class="header_top_li">
            <!-- <img src="../assets/images/header_img1.png" alt="" srcset="">
            <div>{{ $t("message.header.header1") }}：{{ contnetData.service_tel }}</div> -->
            <div @click="DownloadApp">{{ $t("message.header.header1") }}</div>
          </div>
          <div class="header_top_li" @click="$router.push('/shoppingCart')">
            <img src="../assets/images/header_img4.png" alt="" srcset="">
            <div>{{ $t("message.header.header2") }}</div>
          </div>
          <div class="header_top_li" @click="goLogin">
            <img src="../assets/images/header_img2.png" alt="" srcset="">
            <div v-if="userInfo == ''">{{ $t("message.header.header3") }}</div>
            <div v-else>Hello，{{ userInfo.nickname }}<span style="margin-left: 20px;" @click="closeBtn">{{ $t("message.header.header5") }}</span></div>
          </div>
          <div class="header_top_li">
            <img src="../assets/images/header_img1.png" alt="" srcset="">
            <div class="header_top_right">
              <el-popover
                placement="bottom"
                width="40"
                trigger="click">
                <div class="header_top_tab">
                  <div @click="langChange('cn')" style="cursor: pointer;padding: 10px;">{{ $t("message.header.list1") }}</div>
                  <div @click="langChange('en')" style="cursor: pointer;padding: 10px;">{{ $t("message.header.list2") }}</div>
                  <!-- <div @click="langChange('ms')" style="cursor: pointer;padding: 10px;">{{ $t("message.header.list3") }}</div> -->
                </div>
                <div slot="reference" style="display: flex;align-items: center;">
                  <div>{{ $t("message.header.header4") }}：
                    <span v-if="lang === 'cn'">中文</span>
                    <span v-if="!lang">中文</span>
                    <span v-if="lang === 'en'">English</span>
                    <span v-if="lang === 'ms'">Malay</span>

                  </div>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header_bg header_bg_b">
      <div class="header_tab">
        <img src="../assets/images/logo1.png" alt="" srcset="" @click="$router.push('/')">
        <div class="header_tab_box">
          <div class="header_tab_li" @click="tabBtn(index)" :class="headerIndex == index?'header_active':''" v-for="(item,index) in tabData">{{item.title}}</div>
        </div>
        <div class="header_tab_right">
          <!-- <img src="../assets/images/search.png" alt="" srcset="">
          <el-input type="text" :placeholder='$t("message.header.search")' v-model="search" @keyup.enter.native="searchBtn()"></el-input> -->
        </div>
      </div>
    </div>
	</div>
</template>

<script>
  import vmson from '@/components/emptyVue.js'
  export default {
    components: {
      vmson
    },
    data() {
      return{
        tabData:[{
          title:this.$t("message.header.tab1")
        },{
          title:this.$t("message.header.tab2")
        },{
          title:this.$t("message.header.tab3")
        },{
          title:this.$t("message.header.tab4")
        }],
        headerIndex:0,
        search:'',
        userInfo:'',
        contnetData:'',
        lang:'',
      };
    },
    mounted() {
      vmson.$on('left_num',(options)=>{
        this.headerIndex = options.num
        console.log(options)
      })
      if(sessionStorage.getItem('userinfo')){
        this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
      }
      this.config()
    },
    created() {
      this.lang = localStorage.lang
      console.log(localStorage.lang);
    },
    methods: {
      DownloadApp(){
        if(this.contnetData.app_url){
          window.open(this.contnetData.app_url)
        }
      },
      //语言切换
      langChange(e) {
        localStorage.setItem("lang", e);
        this.lang = e
        this.$i18n.locale = e;
        location.reload()
      },
      searchBtn(){
        this.$router.push('/productList')
      },
      tabBtn(index){
        this.headerIndex = index
        if(index == 0){
          this.$router.push('/')
        }else if(index == 1){
          this.$router.push('/productList')
        }else if(index == 2){
          this.$router.push('/aboutUs')
        }else if(index == 3){
          this.$router.push('/contactUs')
        }
      },
      goLogin(){
        if(this.userInfo == ''){
          this.$router.push('/login')
        }else if(this.$route.path != '/personal'){
          this.$router.push('/personal')
        }
      },
      closeBtn(){
        sessionStorage.setItem('userinfo', '');
        this.$message({
          message: '退出成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000);
      },
      config(){
        this.request.post("/api/index/config", {
        }).then(res => {
          console.log(res,111111111111)
          this.contnetData = res.data
        })
      }
    },
  };
  </script>

<style scoped lang="scss">
.header{
  width: 100%;
  .header_bg{
    width: 100%;
    background: #F4F1E9;
  }
  .header_bg_b{
    background: #fff;
  }
  .header_box{
    width: 1200px;
    margin: 0 auto;
    .header_top{
      display: flex;
      justify-content: flex-end;
      padding: 10px 0;
      .header_top_li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 50px;
        font-size: 14px;
        color: #999;
        cursor: pointer;
        img{
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        .header_top_right{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          i{
            padding-left: 6px;
          }
          
        }
      }
    }
  }
  .header_tab{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 12px 0 18px;
    img{
      width: 197px;
      cursor: pointer;
      // height: 58px;
    }
    .header_tab_box{
      width: 500px;
      display: flex;
      justify-content: space-between;
      .header_tab_li{
        // width: 70px;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        padding: 0 10px 6px;
      }
      .header_active{
        color: #C39A6B;
        border-bottom: 3px solid #C39A6B;
      }
    }
    .header_tab_right{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #979595;
      img{
        width: 18px;
        height: 18px;
      }
      :deep(){
        .el-input__inner{
          border: 0;
        }
      }
    }
  }
}
</style>