import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
  message: {
    header: {
		header1: '下载APP',
		header2: '购物车',
		header3: '登录/注册',
		header4: '切换语言',
		header5: '退出登录',
		list1: '中文',
		list2: 'English',
		list3: '马来语',
		tab1: '首页',
		tab2: '产品',
		tab3: '关于我们',
		tab4: '联系我们',
		search: '搜索',
    },
    home: {
		service1: "免费送货和退货",
		service2: "订单满99美元免运费",
		service3: "客服支持24*7",
		service4: "随时获得完美支持",
		service5: "100%安全支付",
		service6: "我们确保安全付款",
		list1: '产品推荐',
		list2: '新闻资讯',
		btn: '立即购买',
    },
	form: {
		name: '用户名',
		email: '邮箱',
		emailCode: '邮箱验证码',
		playEmailCode: '获取邮箱验证码',
		message: '留言',
		address: '地址',
		contact: '联系方式',
		password: '密码',
		newPassword: '新密码',
		confirmPassword: '确认密码',
		logOn: '登录',
		submitTo: '提交',
		submitInformation: '提交信息',
		contactTips:'您的电子邮件地址不会被公开。必需的地方已做标记',
		forgot: '忘记密码',
		title: 'MYQUEEN来自新加坡高端护肤品牌，倡导“科学修复肌肤”的护肤理念。我们深知东方文化的传统和我们对年轻肌肤的热情，使用独家配方和尖端技术创造创新的护肤产品。',
		go1:"还没有账号？去注册 ",
		go2:"已有账号？去登录",
		register:"注册",
		invitationCode:"邀请码",
		partition:"选择分区",
		leftRegion:"左区",
		rightRegion:"右区",
		registrationDate:"注册日期",
		mySuperior:"我的上级",
		ContactPerson:"接点人账号",
	},
	login: {
		tips1: '阅读并同意',
		tips2: 'MY QUEEN用户协议',
		tips3: '我已知晓',
	},
	personal: {
		left1: '个人中心',
		left2: '交易管理',
		left3: '我的订单',
		left4: '财务中心',
		left5: '数据展示',
		left6: '我的钱包',
		left7: '资金明细',
		left8: '我的推广',
		left9: '用户设置',
		left10: '个人信息',
		left11: '修改密码',
		left12: '收货地址',
		left13: '银行卡',
		right1: '邮箱',
		right2: '当前PV值',
		right3: '剩余积分',
		right4: '今日新增直推人数',
		right5: '今日新增团队人数',
		right6: '剩余对碰额度',
		right7: '下一PV值',
		right8: '个',
		right9: '用户',
		right10: '你好',
	},
	order: {
		tab1: '全部订单',
		tab2: '待付款',
		tab3: '待发货',
		tab4: '待收货',
		tab5: '已完成',
		tab6: '已取消',
		tab7: '确认收货',
		tr1: '商品信息',
		tr2: '实付款',
		tr3: '交易状态',
		tr4: '操作',
		con1: '交易时间',
		con2: '订单编号',
		con3: '物流单号',
		con4: '物流公司',
	},
	data: {
		title1:'钱包余额',
		title2:'剩余对碰额度',
		title3:'今日新增直推人数',
		title4:'今日新增团队人数',
		title5:'左区总业绩',
		title6:'右区总业绩',
		title7:'数据展示',
		title8:'总业绩',
	},
	wallet: {
		title1: '余额',
		title2: 'PV钱包',
		title3: '积分',
		title4: '账户充值',
		title5: '申请提现',
		title6: '提现记录',
		title7: '账户转账',
		title8: '转账给朋友',
		title9: '转账到PV钱包',
		form1: 'PV钱包',
		form2: '充值金额',
		form3: '支付方式',
		form4: '银行卡',
		form5: '选择币种',
		form6: '提现金额',
		form7: '转账方式',
		form8: '收款账户',
		form9: '转账金额',
		form10: '余额转账',
		form11: '银行户口',
		form12: '银行名称',
		form13: '银行账号',
		form14: '上传支付凭证',
		form15: '提交审核',
		form16: '实时到账',
		form17: '线下支付',
		tr1: '申请日期',
		tr2: '收款账户',
		tr3: '收款人',
		tr4: '转账金额',
		tr5: '状态',
		btn1: '提交',
		btn2: '提现',
		btn3: '转账',
		radio1: '美元',
		radio2: '人民币',
		radio3: '新币',
		radio4: '马币',
		status1: '审核中',
		status2: '已完成',
		status3: '已拒绝',
	},
	fund: {
		title1: '余额明细',
		title2: 'PV钱包明细',
		title3: '积分明细',
		title4: '日期',
		title5: '金额',
		title6: '备注',
	},
	promotion: {
		title1: '直推人数',
		title2: '人',
		title3: '推荐网格图',
		title4: '姓名',
		title5: '会员等级',
		title6: '注册时间',
		title7: '会员',
		title8: '复制分享链接',
		title9: '复制邀请码',
		title10: '团队人数',
	},
	address: {
		title1: '新增地址',
		title2: '收货人',
		title3: '手机号',
		title4: '收货地址',
		title5: '操作',
		title6: '修改',
		title7: '收货人',
		title8: '手机号',
		title9: '选择国家',
		title10: '详细地址',
	},
	product: {
		title1: '首页',
		title2: '产品列表',
		title3: '产品详情',
		title4: '立即购买',
		title5: '加入购物车',
		title6: '退货免运费',
		title7: '详情描述',
		title8: '成分',
		title9: '功效',
		title10: '使用方法'
	},
	shopping: {
		title1: '商品名称',
		title2: '售价',
		title3: '数量',
		title4: '小计',
		title5: '操作',
		title6: '继续购物',
		title7: '已选择',
		title8: '件',
		title9: '合计',
		title10: '去结算',
		title11: '我的购物车',
		title12: '填写核对订单',
		title13: '成功提交订单',
		title14: '选择收货地址',
		title15: '添加新地址',
		title16: '确认商品信息',
		title17: '返回购物车',
		title18: '商品件数',
		title19: '金额合计',
		title20: '应付总额',
		title21: '提交订单',
		title22: '支付成功',
		title23: '查看订单',
		title24: '是否提交此订单',
		title25: '取 消',
		title26: '确 定',
		title27: '提示',
		title28: '请选择收货地址',
		title29: '积分支付',
	},
	footer: {
		title1: '关于我们',
		title2: '关于我们',
		title3: '联系我们',
		title4: '用户协议',
		title5: '品牌介绍',
		title6: '客户服务',
		title7: '客户服务',
		title8: '支付方式',
		title9: '帮助中心',
		title10: '问题反馈',
		title11: '我的账户',
		title12: '个人中心',
		title13: '购物车',
		title14: '订单查询',
		title15: '我的钱包',
		title16: '保留所有权利',
	},
	bank: {
		title1: '姓名',
		title2: '选择国家',
		title3: '护照/身份证',
		title4: '银行卡名称',
		title5: '卡号',
		title6: '标*项填写后不可修改，每个会员仅支持一张银行卡',
		title7: '提交',
	}
  },
  ...zhLocale
}
 
export default cn;