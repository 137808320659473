<template>
	<div class="content">
    <Header/>
    <div class="banner_box">
      <el-carousel trigger="click"  height="420px">
        <el-carousel-item v-for="(item,index) in bannerData" :key="index">
          <img :src="$baseUrl + item.image" alt="" srcset="" @click="$router.push('/productDetails?id=' + item.goods_id)">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="tips_box">
      <div class="tips_ul">
        <div class="tips_li">
          <img src="../assets/images/tips_img1.png" alt="" srcset="">
          <div>
            <div class="tips_li_name">{{ $t("message.home.service1") }}</div>
            <div>{{ $t("message.home.service2") }}</div>
          </div>
        </div>
        <div class="tips_xian"></div>
        <div class="tips_li">
          <img src="../assets/images/tips_img2.png" alt="" srcset="">
          <div>
            <div class="tips_li_name">{{ $t("message.home.service3") }}</div>
            <div>{{ $t("message.home.service4") }}</div>
          </div>
        </div>
        <div class="tips_xian"></div>
        <div class="tips_li">
          <img src="../assets/images/tips_img3.png" alt="" srcset="">
          <div>
            <div class="tips_li_name">{{ $t("message.home.service5") }}</div>
            <div>{{ $t("message.home.service6") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="product_box">
        <div v-if="contnetData.hot">
          <div class="product_title">{{ $t("message.home.list1") }}</div>
          <div class="product_h">Products Recommended</div>
          <div class="product_top">
            <img src="../assets/images/home_img1.png" alt="" srcset="">
            <img :src="$baseUrl + contnetData.hot.hot_image" alt="" srcset="" class="product_top_sp">
            <img src="../assets/images/home_img2.png" alt="" srcset="">
          </div>
          <div class="product_top_name">{{ contnetData.hot.name }}</div>
          <div class="product_top_title">{{ contnetData.hot.title }}</div>
          <div class="product_top_btn" @click="$router.push('/productDetails?id=' + contnetData.hot.id)">{{ $t("message.home.btn") }}</div>
        </div>
        <div class="product_two" v-if="contnetData.goods[0]">
          <div class="product_two_bg">
            <div class="product_two_name">{{ contnetData.goods[0].name }}</div>
            <div class="product_two_title">{{ contnetData.goods[0].title }}</div>
            <div class="product_two_btn" @click="$router.push('/productDetails?id=' + contnetData.goods[0].id)">{{ $t("message.home.btn") }}</div>
            <img src="../assets/images/home_img3.png" alt="" srcset="" class="product_two_img1">
            <img :src="$baseUrl + contnetData.goods[0].hot_image" alt="" srcset="" class="product_two_img2">
          </div>
        </div>
        <div class="product_three" v-if="contnetData.goods[1]">
          <img :src="$baseUrl + contnetData.goods[1].hot_image" alt="" srcset="" class="product_three_img1">
          <div>
            <div class="product_three_name">{{ contnetData.goods[1].name }}</div>
            <div class="product_three_title">{{ contnetData.goods[1].title }}</div>
            <div class="product_three_btn" @click="$router.push('/productDetails?id=' + contnetData.goods[1].id)">{{ $t("message.home.btn") }}</div>
          </div>
          <img src="../assets/images/home_img4.png" alt="" srcset="" class="product_three_img2">
        </div>
        <div class="product_four">
          <div class="product_four_li product_four_li_p" v-if="contnetData.goods[2]">
            <div class="product_four_name">{{ contnetData.goods[2].name }}</div>
            <div class="product_four_xian"></div>
            <div class="product_four_title">{{ contnetData.goods[2].title }}</div>
            <div class="product_four_btn" @click="$router.push('/productDetails?id=' + contnetData.goods[2].id)">{{ $t("message.home.btn") }}</div>
            <!-- <img src="../assets/images/home_img5.png" alt="" srcset="" class="product_four_img1"> -->
            <img :src="$baseUrl + contnetData.goods[2].hot_image" alt="" srcset="" class="product_four_img2">
          </div>
          <div class="product_four_li" v-if="contnetData.goods[3]">
            <div class="product_four_name">{{ contnetData.goods[3].name }}</div>
            <div class="product_four_xian"></div>
            <div class="product_four_title">{{ contnetData.goods[3].title }}</div>
            <div class="product_four_btn" @click="$router.push('/productDetails?id=' + contnetData.goods[3].id)">{{ $t("message.home.btn") }}</div>
            <!-- <img src="../assets/images/home_img6.png" alt="" srcset="" class="product_four_img1"> -->
            <img :src="$baseUrl + contnetData.goods[3].hot_image" alt="" srcset="" class="product_four_img2">
          </div>
        </div>
      </div>
    </div>
    <div class="tips_img">
      <el-image :src="tipsImg" ></el-image>
    </div>
    <div class="news">
      <div class="news_title">{{ $t("message.home.list2") }}</div>
      <div class="news_h">News and information</div>
      <div class="news_ul">
        <div class="news_li" v-for="(item,index) in contnetData.news_info" :key="index" @click="$router.push('/newsDetail?id=' + item.id)">
          <el-image :src="$baseUrl + item.image"></el-image>
          <div class="news_li_footer">
            <div class="news_li_left">
              <div class="news_li_time">{{ item.num }}</div>
              <div class="news_li_e">{{ item.month }}</div>
            </div>
            <div>
              <div class="news_li_name">{{ item.name }}</div>
              <div class="news_li_con">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
	</div>  
</template>

<script>
  import Header from '@/components/header.vue'
  import Footer from '@/components/footer.vue'
  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return{
        bannerData:[],
        tipsImg:require('../assets/images/tips_img4.png'),
        newsImg:require('../assets/images/news_img1.png'),
        contnetData:'',
      };
    },
    mounted() {
      this.index()
      this.banner()
    },
    methods: {
      index(){
        this.request.post("/api/index/index", {
        }).then(res => {
          console.log(res)
          this.contnetData = res.data
        })
      },
      banner(){
        this.request.post("/api/index/banner", {
          type:2
        }).then(res => {
          console.log(res)
          this.bannerData = res.data
        })
      },
    },
  };
  </script>

<style scoped lang="scss">
.content{
  .banner_box{
    img{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
      background-color: #d3dce6;
    }
  }
  .tips_box{
    width: 100%;
    background: #FAF9F5;
    .tips_ul{
      width: 1200px;
      margin: 0 auto;
      padding: 14px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tips_li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        img{
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
        .tips_li_name{
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 6px;
        }
      }
      .tips_xian{
        width: 1px;
        height: 40px;
        background: #C39A6B;
      }
    }
  }
  .tips_img{
    width: 100%;
    height: auto;
    margin-top: 140px;
  }
  .product{
    .product_box{
      .product_title{
        font-size: 28px;
        font-weight: bold;
        padding-top: 140px;
      }
      .product_h{
        font-size: 28px;
        color: #9E9E9E;
        padding-bottom: 60px;
      }
      .product_top{
        width: 1200px;
        margin: 0 auto;
        .product_top_sp{
          width: 700px;
          height: 600px;
          margin: 0 80px 0 134px;
        }
      }
      .product_top_name{
        font-size: 28px;
        font-weight: bold;
        padding: 20px 0 6px;
      }
      .product_top_title{
        font-size: 20px;
        width: 530px;
        margin: 0 auto;
      }
      .product_top_btn{
        width: 170px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 14px;
        margin: 40px auto;
        background: #333;
        cursor: pointer;
      }
      .product_two{
        width: 1200px;
        margin: 100px auto 0;
        .product_two_bg{
          width: 500px;
          height: 520px;
          background: #FFF1E1;
          position: relative;
          .product_two_name{
            font-size: 28px;
            font-weight: bold;
            padding: 140px 0 0 230px;
            position: relative;
            z-index: 9;
          }
          .product_two_title{
            width: 350px;
            font-size: 20px;
            padding: 10px 0 0 240px;
            position: relative;
            z-index: 9;
            text-align: left;
          }
          .product_two_btn{
            width: 170px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 14px;
            margin: 20px 0 0 240px;
            background: #333;
            cursor: pointer;
            position: relative;
            z-index: 9;
          }
          .product_two_img1{
            position: absolute;
            left: -70px;
            bottom: -90px;
          }
          .product_two_img2{
            position: absolute;
            left: 400px;
            top: 45px;
            z-index: 1;
            height: 600px;
            width: 600px;
          }
        }
      }
      .product_three{
        width: 1200px;
        margin: 130px auto 0;
        border: 1px solid #C39A6B;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 70px 0;
        .product_three_name{
          font-size: 28px;
          font-weight: bold;
        }
        .product_three_title{
          width: 350px;
          font-size: 20px;
          text-align: left;
        }
        .product_three_btn{
          width: 170px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          color: #fff;
          font-size: 14px;
          margin: 40px auto;
          background: #333;
          cursor: pointer;
        }
        .product_three_img1{
          padding-right: 200px;
        }
        .product_three_img2{
          width: 275px;
          height: 275px;
          position: absolute;
          right: -100px;
          bottom: -100px;
        }
      }
      .product_four{
        width: 1200px;
        margin: 180px auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .product_four_li{
          width: 520px;
          height: 488px;
          position: relative;
          z-index: 9;
          background: url('../assets/images/home_img5.png') 100% no-repeat;
          .product_four_name{
            width: 200px;
            font-size: 28px;
            font-weight: bold;
            padding-top: 80px;
            padding-left: 90px;
            margin: 0 auto;
          } 
          .product_four_xian{
            width: 228px;
            height: 1px;
            background: #C39A6B;
            margin-left: 200px;
            margin-top: 20px;
          }
          .product_four_title{
            width: 270px;
            font-size: 20px;
            text-align: left;
            padding-left: 180px;
            padding-top: 30px;
          }
          .product_four_btn{
            width: 170px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 14px;
            // margin: 40px auto;
            background: #333;
            cursor: pointer;
            margin-left: 230px;
            margin-top: 50px;
          }
          .product_four_img1{
            
            position: absolute;
            left: -50px;
            top: 10px;
            z-index: 1;
          }
          .product_four_img2{
            width: 250px;
            height: 250px;
            position: absolute;
            left: -100px;
            bottom: 20px;
            z-index: 10;
          }
        }
        .product_four_li_p{
          background: url('../assets/images/home_img6.png') 100% no-repeat;
        }
      }
    }
  }
  .news{
    width: 1200px;
    margin: 0 auto;
    .news_title{
      font-size: 28px;
      font-weight: bold;
      padding-top: 140px;
    }
    .news_h{
      font-size: 28px;
      color: #9E9E9E;
      padding-bottom: 60px;
    }
    .news_ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .news_li{
        width: 580px;
        padding-bottom: 70px;
        cursor: pointer;
        img{
          width: 580px;
          height: 270px;
        }
        .news_li_footer{
          display: flex;
          justify-content: space-between;
          .news_li_left{
            position: relative;
            padding-right: 40px;
            .news_li_time{
              color: #ECECEC;
              font-size: 150px;
              font-weight: bold;
            }
            .news_li_e{
              font-size: 35px;
              font-weight: bold;
              color: #C39A6B;
              position: absolute;
              left: 28%;
              top: 35%;
            }
          }
          .news_li_name{
            width: 380px;
            font-size: 20px;
            font-weight: bold;
            text-align: left;
            padding-bottom: 6px;
            padding-top: 20px;
          }
          .news_li_con{
            width: 380px;
            font-size: 14px;
            text-align: left;
            text-align: justify;
          }
        }
      }
    }
  }
}
  
</style>