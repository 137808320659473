<template>
	<div class="footer">
    <div class="footer_box">
      <div class="footer_top">
        <img src="../assets/images/logo.png" alt="" srcset="" class="footer_logo">
        <div class="footer_ul">
          <div class="footer_li footer_li_h1">{{ $t("message.footer.title1") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('aboutUs')">{{ $t("message.footer.title2") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('contactUs')">{{ $t("message.footer.title3") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('rich?num=' + 1)">{{ $t("message.footer.title4") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('rich?num=' + 2)">{{ $t("message.footer.title5") }}</div>
        </div>
        <div class="footer_ul">
          <div class="footer_li footer_li_h1">{{ $t("message.footer.title6") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('rich?num=' + 3)">{{ $t("message.footer.title7") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('rich?num=' + 4)">{{ $t("message.footer.title8") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('rich?num=' + 5)">{{ $t("message.footer.title9") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('contactUs')">{{ $t("message.footer.title10") }}</div>
        </div>
        <div class="footer_ul">
          <div class="footer_li footer_li_h1">{{ $t("message.footer.title11") }}</div>
          <div class="footer_li footer_li_c" @click="goPersonal(0)">{{ $t("message.footer.title12") }}</div>
          <div class="footer_li footer_li_c" @click="$router.push('/shoppingCart')">{{ $t("message.footer.title13") }}</div>
          <div class="footer_li footer_li_c" @click="goPersonal(1)">{{ $t("message.footer.title14") }}</div>
          <div class="footer_li footer_li_c" @click="goPersonal(3)">{{ $t("message.footer.title15") }}</div>
        </div>
      </div>
      <div class="footer_tips">
        <img src="../assets/images/footer_img1.png" alt="" srcset="" class="footer_img1">
        <div>{{ contnetData.beian }}</div>
        <img src="../assets/images/footer_img2.png" alt="" srcset="" class="footer_img2">
      </div>
    </div>
	</div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return{
        contnetData:{}
      };
    },
    mounted() {
      this.config()
    },
    methods: {
      config(){
        this.request.post("/api/index/config", {
        }).then(res => {
          console.log(res,111111111111)
          this.contnetData = res.data
        })
      },
      goPersonal(num){
        this.$router.push('personal?idx=' + num)
      }
    },
  };
  </script>

<style scoped lang="scss">
.footer{
  width: 100%;
  padding: 30px 0 13px;
  background: #F4F1E9;
  .footer_box{
    width: 1200px;
    margin: 0 auto;
    .footer_top{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E4DFD4;
      padding-bottom: 10px;
      .footer_logo{
        width: 197px;
        height: 63.11px;
      }
      .footer_ul{
        font-size: 16px;
          color: #333;
        .footer_li{
          padding: 10px 0;
          text-align: left;
        }
        .footer_li_h1{
          font-weight: bold;
        }
        .footer_li_c{
          cursor: pointer;
        }
      }
    }
    .footer_tips{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      padding-top: 12px;
      .footer_img1{
        widows: 159px;
        height: 30px;
      }
      .footer_img2{
        widows: 111px;
        height: 30px;
      }
    }
  }
}
</style>