import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../components/home.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/login.vue"),
        meta: {
            title: '登录'
        }
    },
    {
        path: "/password",
        name: "password",
        component: () => import("../components/password.vue"),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: "/productList",
        name: "productList",
        component: () => import("../components/productList.vue"),
        meta: {
            title: '产品列表'
        }
    },
    {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("../components/aboutUs.vue"),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("../components/contactUs.vue"),
        meta: {
            title: '联系我们'
        }
    },
    {
        path: "/personal",
        name: "personal",
        component: () => import("../components/personal.vue"),
        meta: {
            title: '个人中心'
        }
    },
    {
        path: "/productDetails",
        name: "productDetails",
        component: () => import("../components/productDetails.vue"),
        meta: {
            title: '商品详情'
        }
    },
    {
        path: "/shoppingCart",
        name: "shoppingCart",
        component: () => import("../components/shoppingCart.vue"),
        meta: {
            title: '购物车'
        }
    },
    {
        path: "/orderSubmission",
        name: "orderSubmission",
        component: () => import("../components/orderSubmission.vue"),
        meta: {
            title: '订单确认'
        }
    },
    {
        path: "/success",
        name: "success",
        component: () => import("../components/success.vue"),
        meta: {
            title: '支付成功'
        }
    },
    {
        path: "/rich",
        name: "rich",
        component: () => import("../components/rich.vue"),
        meta: {
            title: '富文本'
        }
    },
    {
        path: "/newsDetail",
        name: "newsDetail",
        component: () => import("../components/newsDetail.vue"),
        meta: {
            title: '新闻详情'
        }
    }
];

const router = new VueRouter({
    routes
});

export default router;