import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
  message: {
    header: {
		header1: 'Download APP',
		header2: 'Shopping Cart',
		header3: 'Login/Sign Up',
		header4: 'Language',
		header5: 'Exit',
		list1: '中文',
		list2: 'English',
		list3: 'Malay',
		tab1: 'Home',
		tab2: 'product',          
		tab3: 'About Us',
		tab4: 'Contact',
		search: 'Search',
    },
    home: {
		service1: "Free Shipping and Returns",
		service2: "Free Shipping on Orders Over $99",
		service3: "Customer Support 24/7",
		service4: "Get Perfect Support Anytime",
		service5: "100% Secure Payment",
		service6: "We Ensure Secure Payment",
		list1: 'New Arrivals',
		list2: 'News',
		btn: 'Buy Now',
    },
	form: {
		name: 'Username',
		email: 'E-mail',
		emailCode: 'Email Verification Code',
		playEmailCode: 'Get Email Verification Code',
		message: 'Messages',
		address: 'Address',
		contact: 'Your phone',
		password: 'Password',
		newPassword: 'New Password',
		confirmPassword: 'Confirm Password',
		logOn: 'Login',
		submitTo: 'Submit',
		submitInformation: 'Send',
		contactTips:'Your email address will not be published.',
		forgot: 'Forgot?',
		title: 'MYQUEEN is a high-end skincare brand from Singapore, advocating the philosophy of "scientific skin repair." We understand the traditions of Eastern culture and our passion for youthful skin, utilizing exclusive formulas and cutting-edge technology to create innovative skincare products.',
		go1:"Register",
		go2:"Login",
		register:"Register",
		invitationCode:"Invitation Code",
		partition:"Choose Area",
		leftRegion:"Left",
		rightRegion:"Right",
		registrationDate:"Registration Date",
		mySuperior:"My Upline",
		ContactPerson:"Contact person account",
	},
	login: {
		tips1: 'Read and Agree',
		tips2: 'MY QUEEN User Agreement',
		tips3: 'I Have Been Informed',
	},
	personal: {
		left1: 'Personal',
		left2: 'Transaction',
		left3: 'My Orders',
		left4: 'Financial',
		left5: 'Team Data',
		left6: 'My Wallet',
		left7: 'Funds Detail',
		left8: 'Market Share',
		left9: 'Setting',
		left10: 'My Profile',
		left11: 'Change Password',
		left12: 'Shipping Address',
		left13: 'Bank Card',
		right1: 'Email',
		right2: 'Current PV Value',
		right3: 'Remaining Points',
		right4: 'New Today',
		right5: 'New Team Today',
		right6: 'Remaining Pairing Quota',
		right7: 'Next PV Value',
		right8: 'Number',
		right9: 'User',
		right10: 'Hello',
	},
	order: {
		tab1: 'ALL',
		tab2: 'Payment',
		tab3: 'Shipment',
		tab4: 'Delivery',
		tab5: 'Completed',
		tab6: 'Cancelled',
		tab7: 'Confirm Receipt',
		tr1: 'Product Info',
		tr2: 'Total Paid',
		tr3: 'Transaction Status',
		tr4: 'Action',
		con1: 'Transaction Time',
		con2: 'Order Number',
		con3: 'Tracking Number',
		con4: 'Shipping Carrier',
	},
	data: {
		title1:'Balance',
		title2:'Pairing Quota',
		title3:'Referrals',
		title4:'Teams',
		title5:'Left',
		title6:'Right',
		title7:'Team Data',
		title8:'Total',
	},
	wallet: {
		title1: 'Balance',
		title2: 'PV Wallet',
		title3: 'Points',
		title4: 'Account Recharge',
		title5: 'Request Withdrawal',
		title6: 'Withdrawal Records',
		title7: 'Account Transfer',
		title8: 'Transfer to Friend',
		title9: 'Transfer to PV Wallet',
		form1: 'PV Wallet',
		form2: 'Recharge Amount',
		form3: 'Payment Method',
		form4: 'Bank Card',
		form5: 'Select Currency',
		form6: 'Withdrawal Amount',
		form7: 'Transfer Method',
		form8: 'Receiving Account',
		form9: 'Transfer Amount',
		form10: 'Balance Transfer',
		form11: 'Bank Account',
		form12: 'Bank Name',
		form13: 'Bank Account Number',
		form14: 'Upload Payment Voucher',
		form15: 'Submit for Review',
		form16: 'Real-time Transfer',
		form17: 'Offline Payment',
		tr1: 'Application Date',
		tr2: 'Receiving Account',
		tr3: 'Payee',
		tr4: 'Transfer Amount',
		tr5: 'Status',
		btn1: 'Submit',
		btn2: 'Withdrawal',
		btn3: 'Transfer',
		radio1: 'USD',
		radio2: 'CNY',
		radio3: 'SGD',
		radio4: 'myr',
		status1: 'Under Review',
		status2: 'Completed',
		status3: 'Rejected',
	},
	fund: {
		title1: 'Balance Details',
		title2: 'PV Wallet Details',
		title3: 'Points Details',
		title4: 'Date',
		title5: 'Amount',
		title6: 'Remarks',
	},
	promotion: {
		title1: 'Direct Referrals Count',
		title2: 'Number',
		title3: 'Referrals Tree',
		title4: 'Name',
		title5: 'Level',
		title6: 'Registration Date',
		title7: 'Member',
		title8: 'Copy Share Link',
		title9: 'Copy Invitation Code',
		title10: 'Team Size',
	},
	address: {
		title1: 'Add New Address',
		title2: 'Recipient',
		title3: 'Phone Number',
		title4: 'Shipping Address',
		title5: 'Action',
		title6: 'Modify',
		title7: 'Recipient',
		title8: 'Phone Number',
		title9: 'Select Country',
		title10: 'Detailed Address',
	},
	product: {
		title1: 'index',
		title2: 'Product List',
		title3: 'Detail',
		title4: 'Buy Now',
		title5: 'Add to Cart',
		title6: 'Free Return Shipping',
		title7: 'Description',
		title8: 'Ingredients',
		title9: 'Benefits',
		title10: 'Guide'
	},
	shopping: {
		title1: 'Product Name',
		title2: 'Price',
		title3: 'Quantity',
		title4: 'Subtotal',
		title5: 'Action',
		title6: 'Continue',
		title7: 'Selected',
		title8: 'Items',
		title9: 'Total',
		title10: 'Checkout',
		title11: 'Cart',
		title12: 'Fill and Verify Order',
		title13: 'Order Successfully',
		title14: 'Select Shipping Address',
		title15: 'Add New Address',
		title16: 'Confirm Product Info',
		title17: 'Return to Cart',
		title18: 'Number of Items',
		title19: 'Total Amount',
		title20: 'Total Amount Due',
		title21: 'Place Order',
		title22: 'Payment Successful',
		title23: 'View Order',
		title24: 'Submit this order?',
		title25: 'Cancel',
		title26: 'Confirm',
		title27: 'Notification',
		title28: 'Please Select Shipping Address',
		title29: 'Points Payment',
	},
	footer: {
		title1: 'About Us',
		title2: 'About Us',
		title3: 'Contact',
		title4: 'User Agreement',
		title5: 'Brand Profile',
		title6: 'Customer Service',
		title7: 'Customer Service',
		title8: 'Payment Methods',
		title9: 'Help Center',
		title10: 'Feedback',
		title11: 'My Account',
		title12: 'Personal Center',
		title13: 'Shopping Cart',
		title14: 'Order Inquiry',
		title15: 'My Wallet',
		title16: 'All rights reserved.',
	},
	bank: {
		title1: 'Your Name',
		title2: 'Select Country',
		title3: 'Passport/ID Card',
		title4: 'Bank Card Name',
		title5: 'Card Number',
		title6: 'Once submitted, cannot be modified. Each member is limited to binding only one bank card.',
		title7: 'Submit',
	}
  },
  ...zhLocale
}
 
export default cn;