import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		userInfo: {},
        token:'',
		hasLogin: false,
		appId:'',
		methodId:'',
		goods_id:'',
	},
    mutations: {
        token(token) {
            console.log(token)
            this.token = token;
            sessionStorage.setItem('token', token);
            // this.user_id = user_id;
            // sessionStorage.setItem('user_id', user_id);
            // this.mobile = mobile;
            // sessionStorage.setItem('mobile', mobile);
            // this.username = username;
            // sessionStorage.setItem('username', username);
            // if(rempass){
            //     localStorage.setItem('token', token);
            //     localStorage.setItem('user_id', user_id);
            //     localStorage.setItem('mobile', mobile);
            //     localStorage.setItem('username', username);
            // }
            // router.push('/');
        }
    }
	// mutations: {
	// 	login(state, provider) {
	// 		console.log("**********",state)
	// 		console.log("**********",provider)
	// 		state.hasLogin = true
	// 		state.uerInfo.token = provider.data
	// 		// state.uerInfo.userName = provider.user_name
	// 		uni.setStorage({
	// 			key: 'uerInfo',
	// 			data: provider
	// 		})
	// 	},
	// 	logout(state) {
	// 		state.hasLogin = false
	// 		state.uerInfo = {}
	// 		uni.removeStorage({
	// 			key: 'uerInfo'
	// 		});
	// 	}
	// }
})

export default store;