import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
  message: {
    header: {
      header1: 'Muat turun APP',
      header2: 'kereta belanja',
      header3: 'Login/Registration',
      header4: 'Tukar bahasa',
      header5: 'Log keluar dari log masuk',
      list1: 'Chinese',
      list2: 'Inggeris',
      list3: 'Malay',
      tab1: 'halaman rumah',
      tab2: 'product',
      tab3: 'Tentang kita',
      tab4: 'hubungi kami',
		  search: 'Cari',
    },
    home: {
      service1: "Penghantaran bebas dan kembali",
      service2: "Penghantaran percuma untuk perintah lebih dari $99",
      service3: "Sokongan perkhidmatan pelanggan 24 * 7",
      service4: "Dapatkan sokongan sempurna bila-bila masa",
      service5: "Pembayaran 100% selamat",
      service6: "Kami pastikan bayaran yang selamat",
      list1: 'Rekomendasi Produk',
		  list2: 'Berita dan maklumat',
		  btn: 'Beli Sekarang',

    },
    form: {
      name: 'user name',
      email: 'mailbox',
      emailCode: 'Kod pengesahan e-mel',
      playEmailCode: 'Dapatkan kod pengesahan e-mel',
      message: 'Tinggalkan mesej',
      address: 'alamat',
      contact: 'Maklumat kenalan',
      password: 'Katalaluan',
      newPassword: 'Katalaluan baru',
      confirmPassword: 'Sahkan Katalaluan',
      logOn: 'Login',
      submitTo: 'Hantar',
      submitInformation: 'Hantar Maklumat',
      contactTips:'Alamat e-mel anda tidak akan dikeluarkan. Kawasan yang diperlukan telah ditanda',
      forgot: 'Lupakan kata laluan',
      title: 'MYQUEEN is a high-end skincare brand from Singapore, advocating for the skincare concept of "scientific skin repair". Kami sedar dengan baik tradisi budaya timur dan semangat kami untuk kulit muda, menggunakan formula eksklusif dan teknologi terbaik untuk mencipta produk kulit yang inovatif.',
      go1:"Belum ada akaun? Pergi mendaftar ",
      go2:"Akaun yang wujud? Pergi log masuk",
      register:"daftar",
      invitationCode:"Kod jemputan",
      partition:"Pilih sekatan",
      leftRegion:"Kawasan Kiri",
      rightRegion:"Kawasan kanan",
      registrationDate:"Tarikh pendaftaran",
		  mySuperior:"Atasan saya",
    },
    login: {
      tips1: 'Baca dan setuju',
      tips2: 'MY QUEEN User Agreement',
      tips3: 'Saya sudah tahu.',
    },
    personal: {
      left1: 'Pusat Peribadi',
      left2: 'Pengurusan Transaksi',
      left3: 'Perintah saya',
      left4: 'Pusat Kewangan',
      left5: 'Persembahan data',
      left6: 'My wallet',
      left7: 'Perincian dana',
      left8: 'promosi saya',
      left9: 'Tetapan pengguna',
      left10: 'maklumat peribadi',
      left11: 'Ubah kata laluan',
      left12: 'Alamat penghantaran',
      left13: 'bank card',
      right1: 'mailbox',
      right2: 'Current PV value',
      right3: 'Integral sisa',
      right4: 'Bilangan rujukan langsung baru ditambah hari ini',
      right5: 'Bilangan pasukan baru ditambah hari ini',
      right6: 'Had tekanan yang tersisa',
      right7: 'Nilai PV berikutnya',
      right8: 'individu',
      right9: 'Pengguna',
      right10: 'Hello',
    },
    order: {
      tab1: 'Semua arahan',
      tab2: 'Pembayaran tunggu',
      tab3: 'Untuk dihantar',
      tab4: 'Barang yang akan diterima',
      tab5: 'Selesai',
      tab6: 'Dibatalkan',
		  tab7: 'Sahkan menerima',
      tr1: 'Maklumat Produk',
      tr2: 'Pembayaran sebenar',
      tr3: 'Status transaksi',
      tr4: 'Operasi',
      con1: 'Transaction time',
      con2: 'Nombor perintah',
		  con3: 'Nombor pengesan logistik',
		  con4: 'syarikat logistik',
    },
    data: {
      title1:'Imbangan dompet',
      title2:'Had tekanan yang tersisa',
      title3:'Bilangan rujukan langsung baru ditambah hari ini',
      title4:'Bilangan pasukan baru ditambah hari ini',
      title5:'Jumlah prestasi di kawasan kiri',
      title6:'Jumlah prestasi di kawasan yang betul',
      title7:'Persembahan data',
		  title8:'Total prestasi',
    },
    wallet: {
      title1: 'balance',
      title2: 'PV Wallet',
      title3: 'integral',
      title4: 'Muat semula akaun',
      title5: 'Apply for withdrawal',
      title6: 'Rekod tarik',
      title7: 'Pemindahan akaun',
      title8: 'Pindahkan ke kawan',
		  title9: 'Pindahkan ke PV dompet',
      form1: 'PV Wallet',
      form2: 'Jumlah muat semula',
      form3: 'Kaedah pembayaran',
      form4: 'kad bank',
      form5: 'Pilih Uang',
      form6: 'Jumlah tarik',
      form7: 'Kaedah pemindahan',
      form8: 'Akaun koleksi',
      form9: 'Jumlah pemindahan',
		  form10: 'Pemindahan keseimbangan',
      form11: 'Akaun Bank',
      form12: 'Nama Bank',
      form13: 'Nombor akaun bank',
      form14: 'Muat naik surat bayaran',
      form15: 'Hantar untuk ulasan',
      form16: 'Penerimaan masa sebenar',
		  form17: 'Pembayaran luar talian',
      tr1: 'Tarikh aplikasi',
      tr2: 'Akaun koleksi',
      tr3: 'Pembayar',
      tr4: 'Jumlah pemindahan',
      tr5: 'keadaan',
      btn1: 'Hantar',
      btn2: 'Pengunduran',
      btn3: 'Pemindahan',
      radio1: 'dolar',
      radio2: 'RMB',
      radio3: 'Warna baru',
      radio4: 'pv',
      status1: 'Dalam ulasan',
      status2: 'Selesai',
      status3: 'Ditolak',
    },
    fund: {
      title1: 'Perincian keseimbangan',
      title2: 'Perincian Dompet PV',
      title3: 'Perincian Titik',
      title4: 'tarikh',
      title5: 'money',
      title6: 'Comment',
    },
    promotion: {
      title1: 'Bilangan rujukan langsung',
      title2: 'orang',
      title3: 'Carta Grid Disarankan',
      title4: 'nama',
      title5: 'Aras anggota',
      title6: 'Masa pendaftaran',
      title7: 'ahli',
		  title8: 'Salin pautan berkongsi',
		  title9: 'Copy invitation code',
		  title10: 'Number of team members',
    },
    address: {
      title1: 'Tambah Alamat',
      title2: 'penerima',
      title3: 'Nombor telefon bimbit',
      title4: 'Alamat penghantaran',
      title5: 'Operasi',
      title6: 'ubahsuai',
      title7: 'penerima',
      title8: 'Nombor telefon bimbit',
      title9: 'Pilih Negara',
      title10: 'Alamat terperinci',
    },
    product: {
      title1: 'halaman rumah',
      title2: 'Senarai Produk',
      title3: 'product details',
      title4: 'Beli Sekarang',
      title5: 'Tambah ke cart belanja',
      title6: 'Penghantaran bebas untuk kembalian',
      title7: 'Huraian terperinci',
      title8: 'komponen',
      title9: 'Efeksi',
      title10: 'Kaedah penggunaan'
    },
    shopping: {
      title1: 'Nama produk',
      title2: 'price',
      title3: 'kuantiti',
      title4: 'Subtotal',
      title5: 'Operasi',
      title6: 'Teruskan belanja',
      title7: 'Pilih',
      title8: 'potongan',
      title9: 'total',
      title10: 'Untuk menyelesaikan',
      title11: 'Kart Pembelian saya',
      title12: 'Isi dan sahkan perintah',
      title13: 'Tertib dihantar berjaya',
      title14: 'Pilih alamat penghantaran',
      title15: 'Tambah alamat baru',
      title16: 'Sahkan maklumat produk',
      title17: 'Kembali ke kereta belanja',
      title18: 'Bilangan item',
      title19: 'Jumlah Jumlah',
      title20: 'Jumlah jumlah yang boleh dibayar',
      title21: 'place order',
      title22: 'Pembayaran berjaya',
      title23: 'Papar perintah',
      title24: 'Adakah anda ingin menghantar perintah ini',
      title25: 'Batalkan',
      title26: 'Sahkan',
      title27: 'prompt',
		  title28: 'Sila pilih alamat penghantaran',
      title29: 'Pembayaran titik',
    },
    footer: {
      title1: 'Tentang kita',
      title2: 'Tentang kita',
      title3: 'hubungi kami',
      title4: 'Perjanjian Pengguna',
      title5: 'Perkenalan Tanda',
      title6: 'perkhidmatan pelanggan',
      title7: 'perkhidmatan pelanggan',
      title8: 'Kaedah pembayaran',
      title9: 'Pusat Bantuan',
      title10: 'Balas balik masalah',
      title11: 'Akaun saya',
      title12: 'Pusat Peribadi',
      title13: 'shopping cart',
      title14: 'Pertanyaan Perintah',
      title15: 'Dompet saya',
		  title16: 'Semua hak disimpan',
    },
    bank: {
      title1: 'nama',
      title2: 'Pilih Negara',
      title3: 'Passport/ID card',
      title4: 'Nama kad bank',
      title5: 'Nombor kad',
      title6: 'Selepas mengisi item ditanda * , ia tidak boleh diubahsuai. Setiap ahli hanya menyokong satu kad bank',
      title7: 'Hantar',
    }
  },
  ...zhLocale
}
 
export default cn;